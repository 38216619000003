import * as React from "react";

import { Layout, Title } from "../../components";

const IndexPage = () => {
  return (
    <>
      <title>
        Contact | Cémpes Vastgoed - Dé alleskunner voor uw vastgoedontwikkeling
      </title>

      <Layout>
        <Title text="Contact" />

        <div className="mt-5 bg-green-50 p-3 flex max-w-screen-sm border-l-4 border-green-700">
          <svg
            className="stroke-2 stroke-current text-green-700 h-8 w-8 mr-2 flex-shrink-0"
            viewBox="0 0 24 24"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M0 0h24v24H0z" stroke="none" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2 4-4" />
          </svg>

          <div className="text-green-700">
            <div className="font-bold text-xl">Bedankt!</div>

            <div>Voor het contact opnemen met Cémpes Vastgoed.</div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
